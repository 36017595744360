import React, { useEffect } from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import HamburgerMenu from "react-hamburger-menu"
import { AppBar } from "@material-ui/core"
import { StaticImage } from "gatsby-plugin-image"

import DesktopMenu from "components/menu/DesktopMenu"
import MobileMenu from "components/menu/MobileMenu"
import Edges from "components/edges"

import { useStore } from "store"
import * as theme from "theme"

const menuBreakpoint = 1280

export default props => {
  const { path } = props

  const [
    {
      appState: { menu }
    },
    dispatch
  ] = useStore()

  useEffect(() => {
    dispatch({ type: "SET_MENU", payload: false })
  }, [path, dispatch])

  return (
    <>
      <StyledAppBar position="static">
        <Edges size="lg">
          <Nav>
            <LogoContainer>
              <Link
                to="/"
                onClick={() => dispatch({ type: "SET_MENU", payload: false })}
              >
                <StaticImage
                  src="./sortera_hz.png"
                  alt="Logo"
                  height={50}
                  layout="fixed"
                />
              </Link>
            </LogoContainer>

            <Navigation>
              <DesktopMenu
                className="desktop-menu"
                style={{ marginRight: "10px" }}
              />

              <HamburgerMenuContainer
                onClick={() => dispatch({ type: "TOGGLE_MENU" })}
              >
                <HamburgerMenu
                  color={theme.colors.primary}
                  isOpen={menu}
                  width={35}
                  height={20}
                  strokeWidth={2}
                  menuClicked={() => ""}
                />
              </HamburgerMenuContainer>
            </Navigation>
          </Nav>
        </Edges>
      </StyledAppBar>

      <MobileMenu />
    </>
  )
}

const StyledAppBar = styled(AppBar)`
  && {
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    width: 100%;
    height: 80px;
    display: flex;
    align-items: center;
    background: #fff;
    box-shadow: none;

    @media screen and (min-width: ${theme.mui.breakpoints.values.sm}px) {
      height: 100px;
    }
  }
`

const LogoContainer = styled.div`
  a {
    display: block;
    text-decoration: none;
  }
`

const HamburgerMenuContainer = styled.div`
  padding: 15px;
  transform: translateX(15px);
  cursor: pointer;

  @media screen and (min-width: ${menuBreakpoint}px) {
    display: none;
  }
`

const Nav = styled.nav`
  display: flex;
  align-items: center;
  height: 80px;
  justify-content: space-between;

  @media screen and (min-width: ${theme.mui.breakpoints.values.sm}px) {
    height: 100px;
  }

  .desktop-menu {
    display: none;

    @media screen and (min-width: ${menuBreakpoint}px) {
      display: flex;
    }
  }
`

const Navigation = styled.div`
  display: flex;
  align-items: center;
`
