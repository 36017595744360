import React from "react"
import styled from "styled-components"
import { Typography } from "@material-ui/core"
import { Link } from "gatsby"
import moment from "moment"

// import app components
import Spacer from "components/spacer"
import BackgroundImage from "components/backgroundImage"
import * as theme from "theme"

const NewsPost = props => {
  const { image, date, headline, url, backgroundColor, ...rest } = props

  return (
    <Container {...rest}>
      <Link to={url}>
        <ImageContainer backgroundColor={backgroundColor}>
          {image && <BackgroundImage image={image} />}
        </ImageContainer>
      </Link>

      {date && (
        <Spacer mb={10}>
          <Typography
            variant="h5"
            children={`Posted / ${moment(date).format("MMMM DD, YYYY")}`}
          />
        </Spacer>
      )}
      {headline && (
        <Spacer mb={20}>
          <Link to={url}>
            <Typography variant="h3" children={headline} />
          </Link>
        </Spacer>
      )}
      <Link to={url}>
        <ReadMore children={`Read more`} color="primary" />
      </Link>
    </Container>
  )
}

const Container = styled.div``

const ImageContainer = styled.div`
  position: relative;
  z-index: 1;
  height: 270px;
  width: 100%;
  margin-bottom: 35px;

  &:after {
    content: "";
    position: absolute;
    left: 15px;
    top: 15px;
    z-index: -1;
    width: 100%;
    height: 100%;
    background: ${props =>
      props.backgroundColor || theme.colors.background.dark};
  }
`

const ReadMore = styled(Typography)`
  && {
    font-weight: bold;
  }
`

export default NewsPost
