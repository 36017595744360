import React from "react"
import { graphql } from "gatsby"

// import app components
import FlexibleContent from "components/flexibleContent"

export default function home(props) {
  const {
    data: {
      page: {
        title,
        uri,
        acf: { modules }
      }
    }
  } = props

  return (
    <>
      {!!modules && (
        <>
          <FlexibleContent
            rows={modules}
            data={{
              title,
              uri
            }}
          />
        </>
      )}
    </>
  )
}

export const CollectionQuery = graphql`
  query DefaultTemplate($id: String!) {
    page: wpPage(id: { eq: $id }) {
      title
      slug
      uri
      acf {
        modules {
          ... on WpPage_Acf_Modules_Text {
            __typename
            text
          }
          ... on WpPage_Acf_Modules_TwoColumnsText {
            __typename
            elements {
              text
            }
          }
          ... on WpPage_Acf_Modules_TwoColumnsImageText {
            __typename
            elements {
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(quality: 90, width: 600)
                  }
                }
              }
              text
              button {
                title
                url
              }
            }
          }
          ... on WpPage_Acf_Modules_ThreeColumnsTextAndIcon {
            __typename
            elements {
              icon {
                localFile {
                  childImageSharp {
                    gatsbyImageData(quality: 90, width: 150)
                  }
                }
              }
              headline
              text
            }
          }
          ... on WpPage_Acf_Modules_Banner {
            __typename
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 90, width: 1920)
                }
              }
            }
          }
          ... on WpPage_Acf_Modules_Contact {
            __typename
            formId
            text
          }
          ... on WpPage_Acf_Modules_Team {
            __typename
            layout
            headline
            teamMembers {
              ... on WpTeam {
                id
                title
                uri
                team {
                  jobTitle
                  image {
                    localFile {
                      childImageSharp {
                        gatsbyImageData(quality: 90, width: 500)
                      }
                    }
                  }
                }
              }
            }
          }
          ... on WpPage_Acf_Modules_Logos {
            __typename
            headline
            images {
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(quality: 90)
                  }
                }
              }
            }
          }
          ... on WpPage_Acf_Modules_NewsTeaser {
            __typename
            text
            button {
              title
              url
            }
          }
          ... on WpPage_Acf_Modules_ProductBenefits {
            __typename
            headline
            text
            image1 {
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 90, width: 600)
                }
              }
            }
            image2 {
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 90, width: 600)
                }
              }
            }
            benefits {
              headline
              text
            }
          }
          ... on WpPage_Acf_Modules_PageHeader {
            __typename
            headline
            text
            backgroundVideo {
              localFile {
                publicURL
              }
            }
            breadcrumbs
          }
          ... on WpPage_Acf_Modules_HeadlineText {
            __typename
            headline
            text
          }
          ... on WpPage_Acf_Modules_ImageText {
            __typename
            video {
              mediaItemUrl
              localFile {
                publicURL
              }
            }
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 90, width: 960)
                }
              }
            }
            imageAlignment
            text
            button {
              title
              url
            }
          }

          ... on WpPage_Acf_Modules_News {
            __typename
            fieldGroupName
          }
          ... on WpPage_Acf_Modules_Jobs {
            __typename
            fieldGroupName
          }
          ... on WpPage_Acf_Modules_Hero {
            __typename
            headline
            button {
              title
              url
            }
            backgroundImage {
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 90, layout: FULL_WIDTH)
                }
              }
            }
            backgroundVideo {
              mediaItemUrl
              localFile {
                publicURL
              }
            }
          }
          ... on WpPage_Acf_Modules_VideoWithCarousel {
            __typename
            leftHeadline
            rightHeadline
            leftImage {
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 90, layout: FULL_WIDTH)
                }
              }
            }
            leftVideo {
              mediaItemUrl
              localFile {
                publicURL
              }
            }
            carouselImages {
              image {
                id
                caption
                localFile {
                  childImageSharp {
                    gatsbyImageData(quality: 90, layout: FULL_WIDTH)
                  }
                }
              }
            }
            headline
            text
          }
        }
      }
    }
  }
`
