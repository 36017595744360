import React, { useState } from "react"
import styled, { css } from "styled-components"
import { Dialog, IconButton } from "@material-ui/core"
import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled"

// import app components
import Textarea from "components/textarea"
import Spacer from "components/spacer"
import BackgroundImage from "components/backgroundImage"
import Button from "components/button"
import BackgroundVideo from "components/backgroundVideo"
import Edges from "components/edges"

import * as theme from "theme"

const Hero = props => {
  const { image, imageAlignment, text, button, video } = props

  const [open, setOpen] = useState(false)

  return (
    <>
      <Container>
        <ImageContainer
          hasVideo={video?.localFile?.publicURL}
          alignment={imageAlignment}
          onClick={() => video?.localFile?.publicURL && setOpen(true)}
        >
          {image && <BackgroundImage image={image} />}

          {video?.localFile?.publicURL && (
            <PlayIcon
              onClick={() => setOpen(true)}
              color="primary"
              css={css`
                z-index: 3;
                svg {
                  width: 100px;
                  height: 100px;
                }
              `}
            >
              <PlayCircleFilledIcon />
            </PlayIcon>
          )}
        </ImageContainer>
        <Edges
          size="lg"
          css={css`
            height: 100%;
          `}
        >
          <ContentContainer alignment={imageAlignment}>
            {text && <Textarea content={text} />}

            {button && (
              <Spacer mt={20}>
                <Button to={button.url} children={button.title} />
              </Spacer>
            )}
          </ContentContainer>
        </Edges>
      </Container>

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        fullWidth
        maxWidth={"md"}
      >
        {(video?.localFile?.publicURL || video?.mediaItemUrl) && (
          <BackgroundVideo
            autoPlay={true}
            loop={false}
            muted={false}
            controls={true}
            src={video?.localFile?.publicURL || video?.mediaItemUrl}
          />
        )}
      </Dialog>
    </>
  )
}

const Container = styled.div`
  position: relative;
  border-top: 1px solid ${theme.colors.secondary};
  border-bottom: 1px solid ${theme.colors.secondary};

  display: flex;
  flex-wrap: wrap-reverse;
  align-items: center;
  min-height: 20vw;
`

const ImageContainer = styled.div`
  position: relative;
  height: 200px;
  width: 100%;
  order: 1;
  cursor: ${({ hasVideo }) => (hasVideo ? "pointer" : "default")};
  ${({ alignment }) => (alignment === "right" ? `right: 0;` : `left: 0;`)}

  @media (min-width: 960px) {
    height: auto;
    width: calc(50% - 25px);
    position: absolute;
    top: 0;
    bottom: 0;
  }

  &:hover {
    .icon {
      opacity: 0.8;
    }
  }
`

const PlayIcon = styled(IconButton)`
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 2;
  transform: translate(-50%, -50%);
  transition: ease all 0.2s;

  svg {
    width: 30px;
    height: 30px;
  }
`

const ContentContainer = styled.div`
  width: 100%;

  margin-top: auto;
  margin-bottom: auto;

  ${({ alignment }) =>
    alignment === "right" ? `margin-right: auto;` : `margin-left: auto;`}

  padding: 40px 0;

  @media (min-width: 960px) {
    width: calc(50% - 25px);
  }
`

export default Hero
