import React from "react"
import styled from "styled-components"
import { Typography } from "@material-ui/core"
import { graphql, useStaticQuery } from "gatsby"

// import app components
import Edges from "components/edges"
import Newsletter from "./Newsletter"
import Socials from "./Socials"
import FooterMenu from "./FooterMenu"
import Logo from "icons/logo-small.svg"
import * as theme from "theme"

export default () => {
  const {
    siteOptions: {
      themeOptions: {
        newsletterHeadline,
        newsletterText,
        newsletterFormId,
        newsletterImage,
        facebook,
        linkedin,
        twitter
      }
    }
  } = useOptionsQuery()

  return (
    <>
      <Newsletter
        headline={newsletterHeadline}
        text={newsletterText}
        image={newsletterImage}
        formId={newsletterFormId}
      />

      <Container>
        <StyledEdges size="lg">
          <Content>
            <Legal>
              Copyright &copy; {new Date().getFullYear()} Sortera Alloys. All
              rights reserved. <br />
              <a href="https://jambaree.com/" target="_blank" rel="noreferrer">
                Website by Jambaree
              </a>
            </Legal>

            <StyledSocials
              facebook={facebook}
              twitter={twitter}
              linkedin={linkedin}
            />
          </Content>
          <FooterMenu />
          <Logo />
        </StyledEdges>
      </Container>
    </>
  )
}

const Container = styled.div`
  padding: 35px 0;
  background: ${theme.colors.primary};
`

const StyledEdges = styled(Edges)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: column-reverse;

  @media screen and (min-width: ${theme.mui.breakpoints.values.md}px) {
    flex-direction: row;
  }
`

const Content = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  justify-content: center;
`

const Legal = styled(Typography)`
  && {
    color: #fff;
    font-size: 13px;
    line-height: 16px;
    width: 100%;
    margin: 40px 0 20px;
    text-align: center;

    @media screen and (min-width: ${theme.mui.breakpoints.values.md}px) {
      width: auto;
      margin: 0;
      text-align: left;
    }

    a {
      color: #fff;
    }
  }
`

const StyledSocials = styled(Socials)`
  @media screen and (min-width: ${theme.mui.breakpoints.values.md}px) {
    width: auto;
    margin-left: 40px;
  }
`

const useOptionsQuery = () => {
  const { wp } = useStaticQuery(
    graphql`
      query {
        wp {
          siteOptions {
            themeOptions {
              newsletterHeadline
              newsletterText
              newsletterFormId
              newsletterImage {
                localFile {
                  childImageSharp {
                    gatsbyImageData(quality: 90, layout: FULL_WIDTH)
                  }
                }
              }
              facebook
              linkedin
              twitter
            }
          }
        }
      }
    `
  )
  return wp
}
