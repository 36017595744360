import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { Typography, Grid } from "@material-ui/core"
import Parser from "html-react-parser"
import moment from "moment"

// import app components
import Edges from "components/edges"
import Spacer from "components/spacer"
import Textarea from "components/textarea"
import BackgroundImage from "components/backgroundImage"
import NewsPost from "components/newsPost"
import Button from "components/button"
import * as theme from "theme"

const PostSingle = props => {
  const {
    data: {
      post: {
        id,
        title,
        date,
        news: { image, text }
      },
      posts
    }
  } = props

  const renderPost = index => {
    const o = posts?.nodes && posts.nodes[index]

    return (
      <RelatedPost
        image={o?.news?.image}
        date={o?.date}
        headline={o?.title}
        url={o?.uri}
        backgroundColor={"#fff"}
      />
    )
  }

  let prevPost, nextPost

  const index = posts?.nodes && posts.nodes.map(o => o.id).indexOf(id)

  if (index === 0) {
    prevPost = posts.nodes.length - 1
    nextPost = index + 1
  } else if (index === posts?.nodes?.length - 1) {
    prevPost = index - 1
    nextPost = 0
  } else {
    prevPost = index - 1
    nextPost = index + 1
  }

  return (
    <>
      <Edges size="sm">
        <Spacer pt={{ xs: 30, sm: 50 }} pb={{ xs: 50, md: 100 }}>
          {image && (
            <Spacer mb={50}>
              <ImageContainer>
                <BackgroundImage image={image} />
              </ImageContainer>
            </Spacer>
          )}

          {date && (
            <Spacer mb={10}>
              <Typography
                variant="h5"
                children={`Posted / ${moment(date).format("MMMM DD, YYYY")}`}
              />
            </Spacer>
          )}

          {title && (
            <Spacer mb={50}>
              <Typography variant="h2" children={Parser(title)} />
            </Spacer>
          )}

          <StyledTextarea content={text} />
        </Spacer>
      </Edges>

      <RelatedPostsContainer>
        <Edges size="sm">
          <Spacer pt={60} pb={{ xs: 20, sm: 60 }}>
            <Spacer mb={40}>
              <Grid container alignItems="center" justify="space-between">
                <Spacer mb={{ xs: 30, sm: 0 }} mr={30}>
                  <Typography variant="h2" children="Other News" />
                </Spacer>

                <Button
                  to={`/news-reports`}
                  children={`See all News & Reports`}
                />
              </Grid>
            </Spacer>

            <Grid container justify="space-between">
              {renderPost(prevPost)}
              {renderPost(nextPost)}
            </Grid>
          </Spacer>
        </Edges>
      </RelatedPostsContainer>
    </>
  )
}

const StyledTextarea = styled(Textarea)`
  && {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      &:not(:first-child) {
        margin-top: 1.5rem;
      }
    }

    p {
      img {
        margin-bottom: 0;
      }
    }

    div {
      max-width: 100%;
    }

    img {
      display: flex;
      width: 100%;
    }

    figure {
      width: 100% !important;
    }
  }
`

const ImageContainer = styled.div`
  position: relative;
  width: calc(100% - 15px);
  height: 240px;

  @media screen and (min-width: ${theme.mui.breakpoints.values.sm}px) {
    height: 380px;
  }

  &:after {
    content: "";
    position: absolute;
    left: 15px;
    top: 15px;
    z-index: -1;
    width: 100%;
    height: 100%;
    background: ${theme.colors.background.dark};
  }
`

const RelatedPostsContainer = styled.div`
  background: ${theme.colors.background.dark};
`

const RelatedPost = styled(NewsPost)`
  width: 100%;
  margin-bottom: 40px;

  @media screen and (min-width: ${theme.mui.breakpoints.values.sm}px) {
    width: calc(50% - 20px);
    margin-bottom: 0;
  }
`

export const CollectionQuery = graphql`
  query DefaultSinglePost($id: String!) {
    post: wpPost(id: { eq: $id }) {
      id
      title
      uri
      date
      news {
        text
        image {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 90, layout: FULL_WIDTH)
            }
          }
        }
      }
    }
    posts: allWpPost {
      nodes {
        id
        title
        uri
        date
        news {
          text
          image {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 90, layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
  }
`

export default PostSingle
