import React from "react"
import styled from "styled-components"

// import app components
import Header from "components/header"
import Footer from "components/footer"
import * as theme from "theme"

export default props => {
  return (
    <Container>
      <Header />
      <Main>{props?.children}</Main>
      <Footer />
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 80px;
  min-height: 100vh;

  @media screen and (min-width: ${theme.mui.breakpoints.values.sm}px) {
    padding-top: 100px;
  }
`

const Main = styled.main`
  overflow-x: hidden;
  flex: 1;
`
