import { generateMedia } from "styled-media-query"

import mui from "../theme/mui"

const breakpoints = {}

Object.keys(mui.breakpoints.values).map(key => {
  breakpoints[key] = mui.breakpoints.values[key] + "px"
  return null
})

const media = generateMedia(breakpoints)

export default media
