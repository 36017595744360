import React from "react"
import styled from "styled-components"
import { Typography, Grid } from "@material-ui/core"
import Parser from "html-react-parser"
import Img from "gatsby-image"

// import app components
import Edges from "components/edges"
import Spacer from "components/spacer"
import * as theme from "theme"

const ThreeColumnsTextAndIcon = props => {
  const { elements } = props

  return (
    <Spacer mt={{ xs: 40, sm: 60, md: 80 }} mb={{ xs: 20, sm: 40, md: 60 }}>
      <Edges size="lg">
        <Grid container>
          {elements &&
            elements.map((o, i) => {
              return (
                <Grid key={i} item xs={12} sm={6} md={4}>
                  <Spacer pl={10} pr={10} mb={30}>
                    <IconContainer>
                      {o.icon?.localFile?.childImageSharp?.fixed ? (
                        <Img fixed={o.icon.localFile.childImageSharp.fixed} />
                      ) : (
                        <img src={o?.icon?.sourceUrl} alt="" />
                      )}
                    </IconContainer>

                    {o.headline && (
                      <Spacer mb={20}>
                        <Headline variant="h3" children={Parser(o.headline)} />
                      </Spacer>
                    )}

                    {o.text && <Text children={Parser(o.text)} />}
                  </Spacer>
                </Grid>
              )
            })}
        </Grid>
      </Edges>
    </Spacer>
  )
}

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px;

  @media screen and (min-width: ${theme.mui.breakpoints.values.sm}px) {
    height: 200px;
  }
`

const Headline = styled(Typography)`
  && {
    text-align: center;
  }
`

const Text = styled(Typography)`
  && {
    text-align: center;
  }
`

export default ThreeColumnsTextAndIcon
