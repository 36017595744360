import React from "react"
import styled from "styled-components"
import MenuItems from "./MenuItems"
import { Link } from "gatsby"
import { Typography } from "@material-ui/core"
import Parser from "html-react-parser"

// import app components
import * as theme from "theme"
import { getUrlPath } from "utils"

const DesktopMenu = props => {
  return (
    <Menu {...props}>
      <MenuItems name="main">
        {items => {
          return items
            .filter(item => item.parentDatabaseId === 0)
            .map(({ id, url, active, activeParent, label, childItems }) => (
              <MenuItem key={id}>
                {childItems && childItems.nodes.length ? (
                  <>
                    <MenuLink
                      to={url}
                      activeStyle={{ color: theme.colors.primary }}
                    >
                      <Typography
                        color="inherit"
                        component="div"
                        variant="body2"
                        children={label}
                      />
                    </MenuLink>

                    <SubMenu className="sub-menu">
                      {childItems.nodes.map((o, i) => (
                        <SubMenuItem
                          key={i}
                          to={o.url}
                          activeClassName="active"
                        >
                          <Typography
                            color="inherit"
                            component="div"
                            variant="body2"
                            children={Parser(o.label)}
                          />
                        </SubMenuItem>
                      ))}
                    </SubMenu>
                  </>
                ) : (
                  <MenuLink
                    to={url}
                    activeStyle={{ color: theme.colors.primary }}
                  >
                    <Typography
                      color="inherit"
                      component="div"
                      variant="body2"
                      children={Parser(label)}
                    />
                  </MenuLink>
                )}
              </MenuItem>
            ))
        }}
      </MenuItems>
    </Menu>
  )
}

export default DesktopMenu

const Menu = styled.div`
  display: flex;
  a {
    text-decoration: none;
  }
`
const MenuItem = styled.div`
  display: flex;
  position: relative;

  &:hover {
    .sub-menu {
      opacity: 1;
      pointer-events: all;
    }
  }
  .sub-menu {
    opacity: 0;
    pointer-events: none;
  }
`

const MenuLink = styled(Link)`
  padding: 20px 10px;
  margin-left: 20px;
  color: ${theme.colors.text.dark};

  &:hover {
    color: ${theme.colors.primary};
  }
`

const SubMenu = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 100%;
  left: calc(50% + 15px);
  transform: translateX(-50%);
  background: linear-gradient(90.36deg, #ffffff 0.31%, #eef9f9 100.2%);
  border: 1px solid ${theme.colors.background.dark};

  &:before {
    content: "";
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -8px;
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid #eef9f9;
  }
`

const SubMenuItem = styled(Link)`
  padding: 15px 30px;
  flex-shrink: 0;
  white-space: nowrap;
  color: ${theme.colors.text.dark};

  &:hover,
  &.active {
    background: ${theme.colors.primary};
    color: #fff;
  }
`
