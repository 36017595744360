import React from "react"
import styled from "styled-components"
import { Typography, Grid } from "@material-ui/core"
import Parser from "html-react-parser"

// import app components
import Edges from "components/edges"
import Spacer from "components/spacer"
import BackgroundImage from "components/backgroundImage"
import * as theme from "theme"

const ProductBenefits = props => {
  const { headline, text, image1, image2, benefits } = props

  return (
    <Container>
      <Spacer pt={{ xs: 40, sm: 60 }} pb={{ xs: 10, sm: 60 }}>
        <Edges size="lg">
          <Spacer mb={{ xs: 20, md: 70 }}>
            <Grid container>
              <Grid item xs={12} md={4}>
                <Spacer pr={{ md: 40, lg: 120 }} mb={{ xs: 50, md: 0 }}>
                  {headline && (
                    <Spacer mb={20}>
                      <Typography variant="h2" children={Parser(headline)} />
                    </Spacer>
                  )}
                  {text && <Typography children={Parser(text)} />}
                </Spacer>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <ImageContainer>
                  {image1 && <BackgroundImage image={image1} />}
                </ImageContainer>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <ImageContainer>
                  {image2 && <BackgroundImage image={image2} />}
                </ImageContainer>
              </Grid>
            </Grid>
          </Spacer>

          {benefits && (
            <Grid container>
              {benefits.map((o, i) => {
                return (
                  <Grid key={i} item xs={12} sm={6} lg={3}>
                    <Spacer pr={{ sm: 40 }} mb={{ xs: 50, lg: 0 }}>
                      {o.headline && (
                        <Spacer mb={20}>
                          <Typography
                            variant="h3"
                            children={Parser(o.headline)}
                          />
                        </Spacer>
                      )}
                      {o.text && <Typography children={Parser(o.text)} />}
                    </Spacer>
                  </Grid>
                )
              })}
            </Grid>
          )}
        </Edges>
      </Spacer>
    </Container>
  )
}

const Container = styled.div`
  background: ${theme.colors.background.dark};
`

const ImageContainer = styled.div`
  position: relative;
  z-index: 2;
  height: 270px;
  width: calc(100% - 15px);
  margin-bottom: 40px;

  @media (min-width: ${theme.mui.breakpoints.values.sm}px) {
    width: calc(100% - 40px);
  }

  @media (min-width: ${theme.mui.breakpoints.values.md}px) {
    margin-bottom: 0;
  }

  &:after {
    content: "";
    position: absolute;
    left: 15px;
    top: 15px;
    z-index: -1;
    width: 100%;
    height: 100%;
    background: #fff;
  }
`

export default ProductBenefits
