import React from "react"
import styled from "styled-components"
import { Grid } from "@material-ui/core"

// import app components
import Edges from "components/edges"
import Textarea from "components/textarea"
import Form from "components/form"
import Spacer from "components/spacer"

const Contact = props => {
  const { formId, text } = props

  return (
    <Edges size="lg">
      <Spacer mt={{ xs: 40, sm: 60, md: 80 }} mb={{ xs: 40, sm: 60, md: 80 }}>
        <Grid container justify="space-between">
          <Grid item xs={12} md={5}>
            {formId && (
              <Spacer mb={{ xs: 60, md: 0 }}>
                <Form formId={formId} hideTitle />
              </Spacer>
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            <Spacer mb={20}>{text && <StyledTextarea content={text} />}</Spacer>
          </Grid>
        </Grid>
      </Spacer>
    </Edges>
  )
}

const StyledTextarea = styled(Textarea)`
  && {
    h4 {
      &:not(:first-child) {
        margin-top: 0;
      }
    }
  }
`

export default Contact
