import React from "react"
import styled from "styled-components"
import Parser from "html-react-parser"
import { Link } from "gatsby"

import { Typography } from "@material-ui/core"

import useMenuItems from "../../hooks/useMenuItems"

export default props => {
  const items = useMenuItems("footer")

  return (
    <Container>
      {items &&
        items.map(item => {
          return (
            <div key={item.id}>
              <MenuItem to={item.url}>
                <Typography gutterBottom variant="body2" color="inherit">
                  {item?.label && Parser(item.label)}
                </Typography>
              </MenuItem>
            </div>
          )
        })}
    </Container>
  )
}

const Container = styled.div`
  @media (max-width: 959px) {
    margin: 30px 0;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;

  > div {
    width: calc(50% - 10px);
  }
`
const MenuItem = styled(Link)`
  color: white;
`
