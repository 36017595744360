import React, { useState } from "react"
import styled, { css } from "styled-components"
import Slider from "react-slick"
import Parser from "html-react-parser"

import { IconButton, Dialog, Typography } from "@material-ui/core"
import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled"
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos"
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos"
import CloseIcon from "@material-ui/icons/Close"

import Spacer from "components/spacer"
import BackgroundImage from "components/backgroundImage"
import Edges from "components/edges"
import BackgroundVideo from "components/backgroundVideo"
import HeadlineText from "components/flexibleContent/HeadlineText"

import * as theme from "theme"
import "../../styles/slick.css"

export default props => {
  const {
    leftHeadline,
    rightHeadline,
    leftImage,
    leftVideo,
    carouselImages,
    headline,
    text
  } = props

  const [open, setOpen] = useState(false)

  return (
    <>
      <Spacer
        mt={{ xs: 40, sm: 80 }}
        mb={headline || text ? 0 : { xs: 10, sm: 20 }}
      >
        <Edges size="lg">
          <Content>
            <Column>
              {leftHeadline && (
                <Typography variant="h2" gutterBottom>
                  {leftHeadline}
                </Typography>
              )}
              <Spacer mb={{ xs: 40, sm: 0 }}>
                <ImageContainer
                  onClick={() => setOpen(true)}
                  css={css`
                    ${leftVideo &&
                      css`
                        cursor: pointer;
                      `}
                  `}
                >
                  {leftVideo && (
                    <IconButton
                      onClick={() => setOpen(true)}
                      color="primary"
                      css={css`
                        z-index: 3;
                        svg {
                          width: 100px;
                          height: 100px;
                        }
                      `}
                    >
                      <PlayCircleFilledIcon />
                    </IconButton>
                  )}
                  {leftImage && <BackgroundImage image={leftImage} />}
                </ImageContainer>
              </Spacer>
            </Column>
            <Column>
              {rightHeadline && (
                <Typography variant="h2" gutterBottom>
                  {rightHeadline}
                </Typography>
              )}
              <Spacer mb={{ xs: 40, sm: 0 }}>
                <CarouselSlider
                  {...{
                    dots: false,
                    arrows: true,
                    infinite: true,
                    speed: 500,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    autoplay: true,
                    autoplaySpeed: 5000,
                    pauseOnHover: false,
                    nextArrow: (
                      <Arrow>
                        <ArrowForwardIosIcon />
                      </Arrow>
                    ),
                    prevArrow: (
                      <Arrow>
                        <ArrowBackIosIcon />
                      </Arrow>
                    )
                  }}
                >
                  {carouselImages &&
                    carouselImages.map(({ image }) => {
                      return (
                        <div
                          key={image.id}
                          css={css`
                            position: relative;
                          `}
                        >
                          <ImageContainer>
                            <BackgroundImage image={image} />
                            {image?.caption && (
                              <Caption>
                                <Typography
                                  component="div"
                                  color="inherit"
                                  variant="h2"
                                >
                                  {Parser(image.caption)}
                                </Typography>
                              </Caption>
                            )}
                          </ImageContainer>
                        </div>
                      )
                    })}
                </CarouselSlider>
              </Spacer>
            </Column>
          </Content>
        </Edges>
      </Spacer>

      {(headline || text) && (
        <HeadlineText
          headline={headline}
          text={text}
          disableBorder
          css={css`
            margin-top: -20px;
          `}
        />
      )}

      {(leftVideo?.localFile?.publicURL || leftVideo?.mediaItemUrl) && (
        // fallback to mediaItemUrl for preview
        <Dialog
          open={open}
          onClose={() => setOpen(false)}
          maxWidth={"md"}
          fullWidth
        >
          <CloseButton color="secondary" onClick={() => setOpen(false)}>
            <CloseIcon />
          </CloseButton>

          <BackgroundVideo
            autoPlay={true}
            loop={false}
            muted={false}
            controls={true}
            src={leftVideo?.localFile?.publicURL || leftVideo?.mediaItemUrl}
          />
        </Dialog>
      )}
    </>
  )
}

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 2;
  height: 260px;
  width: calc(100% - 15px);
  margin-bottom: 30px;

  @media (min-width: 600px) {
    height: 380px;
  }

  &:after {
    content: "";
    position: absolute;
    left: 15px;
    top: 15px;
    z-index: -1;
    width: 100%;
    height: 100%;
    background: ${theme.colors.secondary};
  }
`

const Column = styled.div`
  width: 100%;
  height: 100%;
  @media screen and (min-width: ${theme.mui.breakpoints.values.sm}px) {
    width: calc(50% - 15px);
  }
`

const CarouselSlider = styled(Slider)`
  .slick-prev,
  .slick-next {
    height: 50px;
    transform: translate(0, -100%);

    @media (max-width: 768px) {
      display: none !important;
    }
  }

  .slick-prev {
    left: -20px;
  }
`

const Arrow = styled.div`
  path {
    fill: ${theme.colors.primary};
  }
`

const CloseButton = styled(IconButton)`
  position: absolute;
  bottom: calc(100% + 5px);
  left: calc(100% + 5px);
  z-index: 10;

  svg {
    width: 30px;
    height: 30px;
  }
`

const Caption = styled.div`
  position: relative;
  z-index: 1;
  background: rgba(0, 0, 0, 0.55);
  color: #fff;
  padding: 0px 10px;
  p {
    margin: 0;
  }
  text-align: center;
`
