import React, { useState } from "react"
import styled from "styled-components"
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Grid,
  // Dialog,
  // DialogTitle,
  // DialogContent,
  IconButton
} from "@material-ui/core"
// import CloseIcon from "@material-ui/icons/Close"
import { graphql, useStaticQuery } from "gatsby"

// import app components
import Edges from "components/edges"
import Textarea from "components/textarea"
import Button from "components/button"
import Spacer from "components/spacer"
// import Form from "components/form"
import Arrow from "icons/arrow-down.svg"
import * as theme from "theme"

const DEFAULT_DIALOG_STATE = { open: false, jobTitle: "" }

const Jobs = () => {
  const { nodes: jobs } = useJobsQuery()

  const [dialog, setDialog] = useState(DEFAULT_DIALOG_STATE)

  const handleCloseDialog = () => setDialog(DEFAULT_DIALOG_STATE)

  return (
    <>
      <Spacer mt={{ xs: 40, md: 60 }} mb={{ xs: 80, md: 100 }}>
        <Edges size="lg">
          <Grid container justify="flex-end">
            <Grid item xs={12} md={7}>
              {jobs &&
                jobs.map((o, i) => {
                  return (
                    <StyledAccordion key={i}>
                      <StyledAccordionSummary
                        expandIcon={<Arrow />}
                        aria-controls={`panel${i}-content`}
                      >
                        <AccordionTitle
                          variant="h2"
                          color="inherit"
                          children={o.title}
                        />
                      </StyledAccordionSummary>
                      <StyledAccordionDetails>
                        <Spacer p={{ xs: 20, sm: 30 }}>
                          <Spacer mb={30}>
                            {o.job.text && <Textarea content={o.job.text} />}
                          </Spacer>
                          {o?.job?.applyLink?.url ? (
                            <Button
                              to={o.job.applyLink.url}
                              target={o.job.applyLink.target}
                            >
                              {o.job.applyLink.title}
                            </Button>
                          ) : null}
                        </Spacer>
                      </StyledAccordionDetails>
                    </StyledAccordion>
                  )
                })}
            </Grid>
          </Grid>
        </Edges>
      </Spacer>

      {/* <Dialog
        open={dialog.open}
        onClose={handleCloseDialog}
        maxWidth="sm"
        fullWidth
      >
        <StyledIconButton onClick={handleCloseDialog}>
          <CloseIcon />
        </StyledIconButton>

        <DialogTitle>
          <Spacer pt={20} pr={35}>
            <Typography variant="h3" children={dialog.jobTitle} />
          </Spacer>
        </DialogTitle>

        <DialogContent>
          <Spacer pt={20} pb={20}>
            <Form
              formId={3}
              hideTitle
              hiddenFields={[{ id: 5, content: dialog.jobTitle }]}
            />
          </Spacer>
        </DialogContent>
      </Dialog> */}
    </>
  )
}

const useJobsQuery = () => {
  const { allWpJob } = useStaticQuery(
    graphql`
      query {
        allWpJob {
          nodes {
            id
            title
            uri
            date
            job {
              text
              applyLink {
                url
                title
                target
              }
            }
          }
        }
      }
    `
  )
  return allWpJob
}

const StyledAccordion = styled(Accordion)`
  && {
    border-radius: 0;
    box-shadow: none;
    border: 1px solid ${theme.colors.background.dark};
    margin-bottom: 10px;
  }
`

const StyledAccordionSummary = styled(AccordionSummary)`
  && {
    background: ${theme.colors.primary};
    color: #fff;
  }
`

const AccordionTitle = styled(Typography)`
  && {
    text-transform: none;
    font-size: 21px;
    line-height: 28px;
  }
`

const StyledAccordionDetails = styled(AccordionDetails)`
  && {
    padding: 0;
    background: linear-gradient(90.36deg, #ffffff 0.31%, #eef9f9 100.2%);
  }
`

const StyledIconButton = styled(IconButton)`
  && {
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 10;
  }
`

export default Jobs
