import React from "react"
import styled from "styled-components"
import { Typography } from "@material-ui/core"
import Parser from "html-react-parser"

// import app components
import Edges from "components/edges"
import Spacer from "components/spacer"
import BackgroundImage from "components/backgroundImage"
import Form from "components/form"
import ArrowRight from "icons/arrow-right.svg"
import * as theme from "theme"

const Newsletter = props => {
  const { image, headline, text, formId } = props

  return (
    <Container>
      <ImageContainer>
        {image && <BackgroundImage image={image} />}
      </ImageContainer>
      <Edges size="lg">
        <Spacer pt={50} pb={60}>
          <Content>
            {headline && (
              <Row>
                <Column>
                  <Spacer mb={15}>
                    <Typography
                      variant="h2"
                      color="inherit"
                      children={Parser(headline)}
                    />
                  </Spacer>
                </Column>
              </Row>
            )}

            <Row>
              <Column>
                {text && (
                  <Spacer mb={{ xs: 20, sm: 0 }} pr={{ sm: 30 }}>
                    <Typography color="inherit" children={Parser(text)} />
                  </Spacer>
                )}
              </Column>
              <Column>
                {formId && (
                  <StyledForm
                    formId={formId}
                    buttonText={<ArrowRight />}
                    hideTitle
                  />
                )}
              </Column>
            </Row>
          </Content>
        </Spacer>
      </Edges>
    </Container>
  )
}

const Container = styled.div`
  position: relative;
`

const ImageContainer = styled.div`
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;

  .gatsby-image-wrapper {
    mix-blend-mode: multiply;
    background-color: ${theme.colors.primary};
  }
`

const Content = styled.div`
  position: relative;
  z-index: 2;
  color: #fff;
`

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`

const Column = styled.div`
  width: 100%;

  @media screen and (min-width: ${theme.mui.breakpoints.values.sm}px) {
    width: calc(50% - 10px);
  }
`

const StyledForm = styled(Form)`
  && {
    .gravityForm {
      display: flex;
    }

    .field {
      flex: 1;
      margin: 0;
    }

    .form-control {
      background: #fff !important;
    }

    .btn-children {
      line-height: 10px;
    }
  }
`

export default Newsletter
