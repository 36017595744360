import React from "react"

// import app components
import Textarea from "components/textarea"
import Edges from "components/edges"
import Spacer from "components/spacer"

const Text = props => {
  const { text } = props

  return (
    <Spacer mt={{ xs: 40, sm: 60 }} mb={{ xs: 40, sm: 60 }}>
      <Edges size="sm">
        <Textarea content={text} />
      </Edges>
    </Spacer>
  )
}

export default Text
