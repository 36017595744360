import React from "react"
import styled, { css } from "styled-components"
import { Typography, Grid } from "@material-ui/core"
import Parser from "html-react-parser"

// import app components
import Edges from "components/edges"
import Spacer from "components/spacer"
import Textarea from "components/textarea"
import * as theme from "theme"

const HeadlineText = props => {
  const { headline, text, disableBorder, ...rest } = props

  return (
    <Container {...rest} disableBorder={disableBorder}>
      <Spacer pt={{ xs: 40, md: 60 }} pb={{ xs: 30, md: 60 }}>
        <Edges size="lg">
          <Grid container>
            <Grid item xs={12} md={4}>
              {headline && (
                <Spacer mb={{ xs: 20, md: 0 }} pr={{ md: 40 }}>
                  <Typography variant="h2" children={Parser(headline)} />
                </Spacer>
              )}
            </Grid>
            <Grid item xs={12} md={8}>
              {text && <Textarea content={text} />}
            </Grid>
          </Grid>
        </Edges>
      </Spacer>
    </Container>
  )
}

const Container = styled.div`
  ${({ disableBorder }) =>
    !disableBorder &&
    css`
      border-top: 1px solid ${theme.colors.background.dark};
    `}
`

export default HeadlineText
