import React from "react"
import styled from "styled-components"
import { IconButton } from "@material-ui/core"

// import app components
import FacebookIcon from "icons/facebook.svg"
import TwitterIcon from "icons/twitter.svg"
import LinkedinIcon from "icons/linkedin.svg"

const Social = props => {
  const { facebook, twitter, linkedin, ...rest } = props

  return (
    <Container {...rest}>
      {facebook && (
        <Link
          href={facebook}
          target="_blank"
          rel="noreferrer nofollow"
          aria-label="Facebook"
        >
          <IconButton>
            <FacebookIcon />
          </IconButton>
        </Link>
      )}

      {linkedin && (
        <Link
          href={linkedin}
          target="_blank"
          rel="noreferrer nofollow"
          aria-label="Linkedin"
        >
          <IconButton>
            <LinkedinIcon />
          </IconButton>
        </Link>
      )}

      {twitter && (
        <Link
          href={twitter}
          target="_blank"
          rel="noreferrer nofollow"
          aria-label="Twitter"
        >
          <IconButton>
            <TwitterIcon />
          </IconButton>
        </Link>
      )}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
`

const Link = styled.a`
  text-decoration: none;
  display: inline-block;
  margin: 0 10px;
`

export default Social
