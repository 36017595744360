import React from "react"
import styled from "styled-components"
import { Typography } from "@material-ui/core"

// import app components
import Edges from "components/edges"
import Spacer from "components/spacer"
import Button from "components/button"
import BackgroundImage from "components/backgroundImage"
import BackgroundVideo from "components/backgroundVideo"

import * as theme from "theme"

const Hero = props => {
  const { headline, button, backgroundImage, backgroundVideo } = props

  return (
    <Container>
      <StyledEdges size="lg">
        <Content>
          {headline && (
            <Spacer mb={60}>
              <Typography variant="h1" children={headline} color="inherit" />
            </Spacer>
          )}
          {button && <Button to={button.url} children={button.title} />}
        </Content>
      </StyledEdges>
      <ImageContainer>
        {(backgroundVideo?.localFile?.publicURL ||
          backgroundVideo?.mediaItemUrl) && (
          <BackgroundVideo
            src={
              backgroundVideo?.localFile?.publicURL ||
              backgroundVideo?.mediaItemUrl // fallback to mediaItemUrl for preview
            }
          />
        )}

        {backgroundImage && <BackgroundImage image={backgroundImage} />}
      </ImageContainer>
    </Container>
  )
}

const Container = styled.div`
  position: relative;
  height: calc(100vh - 80px);
  display: flex;
  align-items: center;

  @media screen and (min-width: ${theme.mui.breakpoints.values.sm}px) {
    height: calc(100vh - 100px);
  }
`

const StyledEdges = styled(Edges)`
  position: relative;
  z-index: 1;
`

const Content = styled.div`
  max-width: 600px;
  color: #fff;
`

const ImageContainer = styled.div`
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

export default Hero
