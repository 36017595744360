import React from "react"
import styled from "styled-components"
import { Grid } from "@material-ui/core"
import { graphql, useStaticQuery } from "gatsby"

// import app components
import Edges from "components/edges"
import NewsPost from "components/newsPost"
import * as theme from "theme"

const News = props => {
  const { nodes: posts } = usePostQuery()

  return (
    <Edges size="lg">
      <Grid container justify="space-between">
        {posts &&
          posts.map(o => {
            return (
              <StyledNewsPost
                key={o.id}
                image={o.news.image}
                date={o.date}
                headline={o.title}
                url={o.uri}
                backgroundColor={theme.colors.background.dark}
              />
            )
          })}

        <StyledNewsPost style={{ height: 0, overflow: "hidden" }} />
        <StyledNewsPost style={{ height: 0, overflow: "hidden" }} />
      </Grid>
    </Edges>
  )
}

const StyledNewsPost = styled(NewsPost)`
  width: 100%;
  margin-bottom: 40px;

  @media screen and (min-width: ${theme.mui.breakpoints.values.sm}px) {
    width: calc(50% - 20px);
  }

  @media screen and (min-width: ${theme.mui.breakpoints.values.md}px) {
    width: calc(33.3333% - 20px);
    margin-bottom: 80px;
  }
`

const usePostQuery = () => {
  const { allWpPost } = useStaticQuery(
    graphql`
      query {
        allWpPost(sort: { fields: date, order: DESC }) {
          nodes {
            id
            title
            uri
            date
            news {
              text
              image {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(quality: 90, width: 600)
                  }
                }
              }
            }
          }
        }
      }
    `
  )
  return allWpPost
}

export default News
