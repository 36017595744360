import React from "react"
import styled from "styled-components"

// import app components
import BackgroundImage from "components/backgroundImage"
import * as theme from "theme"

const Hero = props => {
  const { image } = props

  return <Container>{image && <BackgroundImage image={image} />}</Container>
}

const Container = styled.div`
  position: relative;
  height: 200px;
  width: 100%;

  @media screen and (min-width: ${theme.mui.breakpoints.values.sm}px) {
    height: 400px;
  }
`

export default Hero
