import { createMuiTheme } from "@material-ui/core/styles"
import colors from "./colors"

const defaultTheme = createMuiTheme()
const { breakpoints } = defaultTheme

export default createMuiTheme({
  typography: {
    fontFamily: "Roboto, Arial, Helvetica",
    h1: {
      fontFamily: "Bai Jamjuree, Helvetica, Arial",
      fontStyle: "normal",
      fontWeight: 300,
      fontSize: "32px",
      lineHeight: "36px",
      textTransform: "uppercase",
      color: colors.primary,
      [breakpoints.up("sm")]: {
        fontSize: "40px",
        lineHeight: "44px"
      },
      [breakpoints.up("lg")]: {
        fontSize: "56px",
        lineHeight: "58px"
      }
    },
    h2: {
      fontFamily: "Bai Jamjuree, Helvetica, Arial",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "24px",
      lineHeight: "32px",
      textTransform: "uppercase",
      color: colors.primary,
      [breakpoints.up("sm")]: {
        fontSize: "28px",
        lineHeight: "36px"
      }
    },
    h3: {
      fontFamily: "Bai Jamjuree, Helvetica, Arial",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "21px",
      lineHeight: "28px",
      letterSpacing: "-0.01em",
      color: colors.text.dark
    },
    h4: {
      fontSize: "18px",
      lineHeight: "28px",
      textTransform: "uppercase",
      color: "#008080"
    },
    h5: {
      fontSize: "14px",
      lineHeight: "16px",
      letterSpacing: "0.06em",
      textTransform: "uppercase",
      color: "#7ba1a4"
    },
    h6: {},
    subtitle1: {},
    subtitle2: {},
    body1: {
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "18px",
      lineHeight: "28px",
      color: colors.text.light
    },
    body2: {
      fontFamily: "Bai Jamjuree, Helvetica, Arial",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "16px",
      lineHeight: "20px",
      color: colors.text.dark
    },
    caption: {},
    button: {}
  },
  palette: {
    primary: {
      main: colors.primary,
      contrastText: "white"
    },
    secondary: {
      main: colors.secondary
    }
  },
  // https://material-ui.com/customization/themes/
  overrides: {
    MuiTypography: {
      gutterBottom: {
        marginBottom: "1rem"
      }
    },
    MuiButton: {
      root: {
        background: colors.primary,
        color: "#fff",
        padding: "14px 24px",
        borderRadius: 0,
        fontSize: "16px",
        fontWeight: 700,
        textTransform: "none",
        letterSpacing: "0.06em",
        "&:hover": {
          backgroundColor: "rgba(0, 0, 0, 0.9)"
        }
      },
      text: {
        padding: "14px 24px"
      }
    },
    MuiPaper: {
      rounded: {
        borderRadius: 0
      }
    },
    MuiDialog: {
      paper: {
        margin: 0
      },
      paperFullWidth: {
        width: "calc(100% - 10px)"
      }
    }
  }
})
