import React from "react"
import { Typography, Grid } from "@material-ui/core"
import Parser from "html-react-parser"

// import app components
import Edges from "components/edges"
import Spacer from "components/spacer"
import Breadcrumbs from "components/breadcrumbs"

const PageHeader = props => {
  const {
    uri,
    headline,
    text,
    // backgroundVideo,
    breadcrumbs
  } = props

  return (
    <Spacer mt={{ xs: 40, sm: 60 }} mb={{ xs: 40, sm: 60 }}>
      <Edges size="lg">
        {breadcrumbs && <Breadcrumbs pathname={uri} />}
        <Grid container>
          <Grid item xs={12} md={5}>
            {headline && (
              <Spacer mb={{ xs: 20, md: 0 }}>
                <Typography variant="h1" children={Parser(headline)} />
              </Spacer>
            )}
          </Grid>
          <Grid item xs={12} md={7}>
            {text && <Typography children={Parser(text)} />}
          </Grid>
        </Grid>
      </Edges>
    </Spacer>
  )
}

export default PageHeader
