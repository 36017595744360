import React from "react"
import { Link } from "gatsby"
import styled, { css } from "styled-components"
import { Button as MuiButton, CircularProgress } from "@material-ui/core"

export default ({ children, to, loading, ...rest }) => {
  const element = (
    <Button {...rest} loading={loading ? 1 : 0}>
      <CircularProgress className="btn-loader" size={25} />
      <span className="btn-children">{children}</span>
    </Button>
  )

  if (to) {
    return to.includes("http") ? (
      <a href={to} target="_blank" rel="noreferrer">
        {element}
      </a>
    ) : (
      <Link to={to}>{element}</Link>
    )
  } else {
    return element
  }
}

const Button = styled(MuiButton)`
  && {
    position: relative;

    .btn-loader {
      position: absolute;
      opacity: 0;
      z-index: 1;
      pointer-events: none;
      left: calc(50% - 12.5px);
    }

    ${({ loading }) =>
      loading &&
      css`
        .btn-children {
          opacity: 0;
        }
        .btn-loader {
          opacity: 1;
        }
      `}
  }
`
