import React from "react"

// import flexible content modules
import Text from "./Text"
import Hero from "./Hero"
import TwoColumnsText from "./TwoColumnsText"
import TwoColumnsImageText from "./TwoColumnsImageText"
import ImageText from "./ImageText"
import NewsTeaser from "./NewsTeaser"
import PageHeader from "./PageHeader"
import Banner from "./Banner"
import HeadlineText from "./HeadlineText"
import Logos from "./Logos"
import ThreeColumnsTextAndIcon from "./ThreeColumnsTextAndIcon"
import ProductBenefits from "./ProductBenefits"
import News from "./News"
import Contact from "./Contact"
import Team from "./Team"
import Jobs from "./Jobs"
import VideoWithCarousel from "./VideoWithCarousel"

export default props => {
  const { rows, data } = props

  if (!!rows) {
    return rows
      .filter(o => o !== null)
      .map(({ __typename, ...rowData }, index) => {
        if (!__typename) {
          return null
        }
        const type = __typename.split("_").slice(-1)[0]
        const components = {
          Text,
          Hero,
          TwoColumnsText,
          TwoColumnsImageText,
          ImageText,
          NewsTeaser,
          PageHeader,
          Banner,
          HeadlineText,
          Logos,
          ThreeColumnsTextAndIcon,
          ProductBenefits,
          News,
          Contact,
          Team,
          Jobs,
          VideoWithCarousel
        }
        const Component = components[type]
        return (
          Component && (
            <>
              <Component
                key={index}
                firstItem={index === 0 ? true : false}
                {...rowData}
                {...data}
              />
            </>
          )
        )
      })
  }
}
