import React from "react"
import styled from "styled-components"
import { Typography, Grid } from "@material-ui/core"
import Img from "gatsby-image"

// import app components
import Edges from "components/edges"
import Spacer from "components/spacer"
import * as theme from "theme"
import BackgroundImage from "components/backgroundImage"
import { GatsbyImage } from "gatsby-plugin-image"

const Logos = props => {
  const { headline, images } = props

  return (
    <Container>
      <Spacer pt={{ xs: 40, sm: 60 }} pb={{ xs: 40, sm: 60 }}>
        <Edges size="lg">
          {headline && (
            <Spacer mb={10}>
              <Headline variant="h2" children={headline} />
            </Spacer>
          )}

          <Grid container justify="center">
            {images &&
              images.map((o, i) => {
                return (
                  <Grid key={i} item xs={12} sm={6} md={4}>
                    <ImageContainer>
                      {/* {o.image?.localFile?.childImageSharp?.fixed ? (
                        <Img fixed={o.image.localFile.childImageSharp.fixed} />
                      ) : (
                        <img src={o.image.sourceUrl} />
                      )} */}

                      {o.image.localFile?.childImageSharp?.gatsbyImageData && (
                        <GatsbyImage
                          image={
                            o.image.localFile?.childImageSharp?.gatsbyImageData
                          }
                        />
                      )}
                    </ImageContainer>
                  </Grid>
                )
              })}
          </Grid>
        </Edges>
      </Spacer>
    </Container>
  )
}

const Container = styled.div`
  border-top: 1px solid ${theme.colors.background.dark};
`

const Headline = styled(Typography)`
  text-align: center;
`

const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
`

export default Logos
