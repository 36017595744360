import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { Typography, Grid, Divider } from "@material-ui/core"
import Parser from "html-react-parser"

// import app components
import Edges from "components/edges"
import Spacer from "components/spacer"
import Textarea from "components/textarea"
import BackgroundImage from "components/backgroundImage"
import * as theme from "theme"

const TeamSingle = props => {
  const {
    data: {
      team: {
        title,
        team: { jobTitle, description, image, patents }
      }
    }
  } = props

  return (
    <>
      <Edges size="lg">
        <Spacer pt={{ xs: 50, sm: 80 }} pb={{ xs: 50, sm: 80 }}>
          <Grid container justify="space-between">
            <Grid item xs={12} md={5}>
              {image && (
                <Spacer mb={{ xs: 40, md: 0 }} pr={{ sm: 40 }}>
                  <ImageContainer>
                    <BackgroundImage image={image} />
                  </ImageContainer>
                </Spacer>
              )}
            </Grid>

            <Grid item xs={12} md={7}>
              {title && (
                <Spacer mb={20}>
                  <Typography variant="h2" children={Parser(title)} />
                </Spacer>
              )}

              {jobTitle && (
                <Spacer mb={{ xs: 30, md: 60 }}>
                  <Typography variant="h5" children={jobTitle} />
                </Spacer>
              )}

              {description && <Textarea content={description} />}
            </Grid>
          </Grid>
        </Spacer>
      </Edges>

      <Divider />

      {patents && patents.length > 0 && (
        <Edges size="lg">
          <Spacer pt={{ xs: 50, sm: 100 }} pb={{ xs: 50, sm: 100 }}>
            <Grid container>
              <Grid item xs={12} md={4}>
                <Spacer mb={{ xs: 40, md: 0 }}>
                  <Typography variant="h2" children={`Patents`} />
                </Spacer>
              </Grid>
              <Grid item xs={12} md={8}>
                <Grid container>
                  {patents &&
                    patents.map(o => {
                      return (
                        <Grid item xs={12} sm={6}>
                          <Spacer mb={20}>
                            <Grid container wrap="nowrap">
                              <Spacer mr={10}>
                                <Plus variant="h2" children={`+`} />
                              </Spacer>
                              <Spacer pr={{ sm: 20 }}>
                                <Typography children={o.title} />
                              </Spacer>
                            </Grid>
                          </Spacer>
                        </Grid>
                      )
                    })}
                </Grid>
              </Grid>
            </Grid>
          </Spacer>
        </Edges>
      )}
    </>
  )
}

const ImageContainer = styled.div`
  position: relative;
  width: calc(100% - 15px);
  max-width: 500px;
  height: 240px;

  @media screen and (min-width: ${theme.mui.breakpoints.values.sm}px) {
    height: 300px;
  }

  @media screen and (min-width: ${theme.mui.breakpoints.values.md}px) {
    height: 300px;
    max-width: 360px;
  }

  &:after {
    content: "";
    position: absolute;
    left: 15px;
    top: 15px;
    z-index: -1;
    width: 100%;
    height: 100%;
    background: ${theme.colors.background.dark};
  }
`

const Plus = styled(Typography)`
  && {
    line-height: 1;
  }
`

export const CollectionQuery = graphql`
  query DefaultSingleTeam($id: String!) {
    team: wpTeam(id: { eq: $id }) {
      id
      title
      uri
      team {
        jobTitle
        description
        image {
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 90, width: 500)
            }
          }
        }
        patents {
          title
        }
      }
    }
  }
`

export default TeamSingle
