import React from "react"
import { Grid, Typography } from "@material-ui/core"
import Parser from "html-react-parser"

// import app components
import Edges from "components/edges"
import Spacer from "components/spacer"

const TwoColumnsText = props => {
  const { elements } = props

  return (
    <Spacer mt={{ xs: 40, sm: 80 }} mb={{ xs: 40, sm: 80 }}>
      <Edges size="lg">
        <Grid container>
          <Grid item xs={12} sm={6}>
            <Spacer mb={{ xs: 30, sm: 0 }}>
              <Typography variant="h3" children={Parser(elements[0].text)} />
            </Spacer>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography children={Parser(elements[1].text)} />
          </Grid>
        </Grid>
      </Edges>
    </Spacer>
  )
}

export default TwoColumnsText
