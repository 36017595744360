import React from "react"
import styled from "styled-components"
import { Grid } from "@material-ui/core"
import Parser from "html-react-parser"
import { graphql, useStaticQuery } from "gatsby"

// import app components
import Edges from "components/edges"
import Spacer from "components/spacer"
import Button from "components/button"
import Textarea from "components/textarea"
import NewsPost from "components/newsPost"
import * as theme from "theme"

const NewsTeaser = props => {
  const { text, button } = props

  const { nodes: posts } = usePostQuery()

  return (
    <Container>
      <Spacer pt={{ xs: 40, sm: 60 }} pb={{ xs: 10, sm: 60 }}>
        <Edges size="lg">
          <Grid container justify="space-between">
            <Content>
              <Spacer mb={{ xs: 40, md: 0 }}>
                {text && (
                  <Spacer mb={30} pr={{ md: 60 }}>
                    <Textarea content={text} />
                  </Spacer>
                )}

                {button && (
                  <Button to={button.url} children={Parser(button.title)} />
                )}
              </Spacer>
            </Content>

            {posts &&
              posts.map(o => {
                return (
                  <StyledNewsPost
                    image={o.news.image}
                    date={o.date}
                    headline={o.title}
                    url={o.uri}
                    backgroundColor="#fff"
                  />
                )
              })}
          </Grid>
        </Edges>
      </Spacer>
    </Container>
  )
}

const Container = styled.div`
  background: ${theme.colors.background.dark};
`

const Content = styled.div`
  width: 100%;

  @media screen and (min-width: ${theme.mui.breakpoints.values.md}px) {
    width: calc(33.3333% - 20px);
  }
`

const StyledNewsPost = styled(NewsPost)`
  width: 100%;
  margin-bottom: 40px;

  @media screen and (min-width: ${theme.mui.breakpoints.values.sm}px) {
    width: calc(50% - 20px);
  }

  @media screen and (min-width: ${theme.mui.breakpoints.values.md}px) {
    width: calc(33.3333% - 20px);
    margin-bottom: 0;
  }
`

const usePostQuery = () => {
  const { allWpPost } = useStaticQuery(
    graphql`
      query {
        allWpPost(limit: 2) {
          nodes {
            id
            title
            uri
            date
            news {
              text
              image {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(quality: 90, width: 600)
                  }
                }
              }
            }
          }
        }
      }
    `
  )
  return allWpPost
}

export default NewsTeaser
