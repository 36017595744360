import React from "react"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"

export default function BackgroundImage(props) {
  const { image, ...rest } = props

  return (
    <Container {...rest}>
      {(image?.localFile?.childImageSharp?.gatsbyImageData ||
        image?.localFile?.childImageSharp?.gatsbyImageData) && (
        <GatsbyImage
          image={
            image?.localFile?.childImageSharp?.gatsbyImageData ||
            image?.localFile?.childImageSharp?.gatsbyImageData
          }
          style={{
            objectFit: "cover",
            objectPosition: "50% 50%",
            fontFamily: "object-fit: cover; object-position: 50% 50%"
          }}
          objectFit="cover"
          objectPosition="50% 50%"
          alt=""
        />
      )}
    </Container>
  )
}

const Container = styled.div`
  z-index: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  overflow: hidden;

  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(
      0,
      0,
      0,
      ${props => (!!props.darkness ? props.darkness : 0.15)}
    );
    opacity: ${props => (!props.overlay ? 0 : 1)};
  }

  .gatsby-image-wrapper {
    height: 100% !important;
    width: 100% !important;
  }
`
