import React from "react"
import styled, { css } from "styled-components"
import MenuItems from "./MenuItems"
import { Link } from "gatsby"
import { Typography } from "@material-ui/core"
import Parser from "html-react-parser"

// import app components
import { useStore } from "store"
import * as theme from "theme"

const MobileMenu = props => {
  const [
    {
      appState: { menu }
    },
    dispatch
  ] = useStore()

  return (
    <>
      <Menu {...props} menuState={menu}>
        <MenuItems name="main">
          {items => {
            return items.map(({ id, url, active, activeParent, label }) => (
              <Item
                onClick={() => dispatch({ type: "SET_MENU", payload: false })}
                key={id}
                to={url}
                activeClassName="active"
              >
                <Title variant="body2">
                  <span>{Parser(label)}</span>
                </Title>
              </Item>
            ))
          }}
        </MenuItems>
      </Menu>

      <Gradient
        onClick={() => dispatch({ type: "SET_MENU", payload: false })}
        menuState={menu}
      />
    </>
  )
}

export default MobileMenu

const menuClose = css`
  transform: translateX(120%);
  opacity: 0;
`
const menuOpen = css`
  transform: translateX(0);
  opacity: 1;
`

const Menu = styled.div`
  ${props => (!!props.menuState ? menuOpen : menuClose)}
  position: fixed;
  height: 100%;
  width: 100%;
  right: 0;
  max-width: 400px;
  z-index: 100;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
  background: ${theme.colors.primary};
  padding-top: 40px;
  top: 80px;

  @media screen and (min-width: ${theme.mui.breakpoints.values.sm}px) {
    top: 100px;
  }
`

const ItemCSS = `
  position: relative;
  color: white;
  text-decoration: none;
  padding: 10px 40px;
  width: 100%;
`

const Item = styled(Link)`
  ${ItemCSS}

  span {
    position: relative;

    &:after {
      content: "";
      position: absolute;
      bottom: -4px;
      left: 0;
      width: 0;
      height: 2px;
      background: #fff;
      transition: ease all 0.2s;
    }
  }

  &.active,
  &:hover {
    span {
      &:after {
        width: 100%;
      }
    }
  }
`

const Title = styled(Typography)`
  padding: 5px 0;
  color: white;
  text-transform: uppercase;
  @media screen and (max-width: ${theme.mui.breakpoints.values.lg}px) {
    color: ${theme.colors.text.dark};
  }
`

const Gradient = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 90;
  background: rgba(0, 0, 0, 0.5);
  opacity: ${props => (!!props.menuState ? 1 : 0)};
  pointer-events: ${props => (!!props.menuState ? "all" : "none")};
  transition: ease all 0.2s;
`
