import React from "react"
import styled from "styled-components"

// import app components
import Edges from "components/edges"
import Textarea from "components/textarea"
import Spacer from "components/spacer"
import BackgroundImage from "components/backgroundImage"
import Button from "components/button"
import * as theme from "theme"

const TwoColumnsImageText = props => {
  const { elements } = props

  return (
    <Spacer mt={{ xs: 40, sm: 80 }} mb={{ xs: 10, sm: 80 }}>
      <Edges size="lg">
        <Content>
          {elements &&
            elements.map((o, i) => {
              return (
                <Column key={i}>
                  <Spacer mb={{ xs: 40, sm: 0 }}>
                    <ImageContainer>
                      {o.image && <BackgroundImage image={o.image} />}
                    </ImageContainer>
                    {o.text && <Textarea content={o.text} />}
                    {o.button && (
                      <Spacer mt={20}>
                        <Button to={o.button.url} children={o.button.title} />
                      </Spacer>
                    )}
                  </Spacer>
                </Column>
              )
            })}
        </Content>
      </Edges>
    </Spacer>
  )
}

const ImageContainer = styled.div`
  position: relative;
  z-index: 2;
  height: 260px;
  width: calc(100% - 15px);
  margin-bottom: 30px;

  @media (min-width: 600px) {
    height: 380px;
    margin-bottom: 55px;
  }

  &:after {
    content: "";
    position: absolute;
    left: 15px;
    top: 15px;
    z-index: -1;
    width: 100%;
    height: 100%;
    background: ${theme.colors.secondary};
  }
`

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`

const Column = styled.div`
  width: 100%;

  @media screen and (min-width: ${theme.mui.breakpoints.values.sm}px) {
    width: calc(50% - 15px);
  }
`

export default TwoColumnsImageText
