import React from "react"
import styled from "styled-components"
import { Typography, Grid } from "@material-ui/core"
import { Link } from "gatsby"

// import app components
import Edges from "components/edges"
import Spacer from "components/spacer"
import BackgroundImage from "components/backgroundImage"
import * as theme from "theme"

const Team = props => {
  const { headline, layout, teamMembers } = props

  return (
    <Container
      background={
        layout === "two"
          ? theme.colors.background.dark
          : theme.colors.background.light
      }
    >
      <Spacer pt={{ xs: 30, md: 30 }} pb={{ xs: 20, md: 20 }}>
        <Edges size="lg">
          {headline && (
            <Spacer mb={40}>
              <Headline variant="h2" children={headline} />
            </Spacer>
          )}

          <Grid container justify="center">
            {teamMembers &&
              teamMembers.map(o => {
                return (
                  <Item key={o?.id} layout={layout}>
                    <Link to={o?.uri}>
                      <ImageContainer layout={layout}>
                        {o?.team?.image && (
                          <BackgroundImage image={o.team.image} />
                        )}
                      </ImageContainer>
                    </Link>

                    {o?.title && (
                      <Spacer mb={10}>
                        <Link to={o?.uri}>
                          <Typography variant="h3" children={o?.title} />
                        </Link>
                      </Spacer>
                    )}

                    {o?.team?.jobTitle && (
                      <Spacer mb={20}>
                        <Typography variant="h5" children={o?.team?.jobTitle} />
                      </Spacer>
                    )}

                    <Link to={o?.uri}>
                      <ReadMore children={`Read more`} color="primary" />
                    </Link>
                  </Item>
                )
              })}

            {/* <Item
              layout={layout}
              style={{ height: 0, padding: 0, margin: 0 }}
            />
            <Item
              layout={layout}
              style={{ height: 0, padding: 0, margin: 0 }}
            /> */}
          </Grid>
        </Edges>
      </Spacer>
    </Container>
  )
}

const Container = styled.div`
  background: ${props => props.background};
`

const Headline = styled(Typography)`
  text-align: center;
`

const Item = styled.div`
  width: 100%;

  margin: 0 20px 40px;

  /* margin-bottom: 40px; */

  @media screen and (min-width: ${theme.mui.breakpoints.values.sm}px) {
    width: calc(50% - 20px);
  }

  @media screen and (min-width: ${theme.mui.breakpoints.values.md}px) {
    ${props =>
      (props.layout === "three" || props.layout === "four") &&
      `
      width: calc(33.3333% - 25px);
    `}
  }

  @media screen and (min-width: ${theme.mui.breakpoints.values.lg}px) {
    ${props =>
      props.layout === "four" &&
      `
      width: calc(25% - 25px);
    `}
  }
`

const ImageContainer = styled.div`
  position: relative;
  z-index: 2;
  height: 240px;
  width: calc(100% - 15px);
  margin-bottom: 40px;

  > div {
    background-color: #fff;
  }

  @media screen and (min-width: ${theme.mui.breakpoints.values.sm}px) {
    height: 30vw;
  }

  @media screen and (min-width: ${theme.mui.breakpoints.values.md}px) {
    height: ${props => (props.layout === "two" ? "340px" : "240px")};
  }

  &:after {
    content: "";
    position: absolute;
    left: 15px;
    top: 15px;
    z-index: -1;
    width: 100%;
    height: 100%;
    background: ${props =>
      props.layout === "two"
        ? theme.colors.background.light
        : theme.colors.background.dark};
  }
`

const ReadMore = styled(Typography)`
  && {
    font-weight: bold;
    font-size: 16px;
  }
`

export default Team
